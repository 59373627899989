export const calculateOrder = (
  currentIndex,
  previousIndex,
  startups
) => {
  /* In order to prevent two quotes from appearing
   * after each other, we calculate and rearrange the order */
  if (currentIndex === 0) {
    return 1;
  } else if (
    startups[previousIndex].quote &&
    startups[currentIndex].quote
  ) {
    return currentIndex + 2;
  } else if (
    currentIndex >= 2 &&
    startups[previousIndex - 1].quote &&
    startups[previousIndex].quote &&
    !startups[currentIndex].quote
  ) {
    return currentIndex;
  } else return currentIndex + 1;
};

export const calculateStartupsContainerHeight = (
  numberOfElements
) => {
  const startupHeight =
    (Math.ceil(numberOfElements / 2) / 2) * 1180;
  const quoteHeight =
    (Math.ceil(numberOfElements / 2) / 2) * 380;
  return startupHeight + quoteHeight;
};
