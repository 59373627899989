export default ({
  palette: { primary, secondary, text },
  fonts,
  spacing,
  breakpoints: { down, up },
}) => ({
  startupCardContainer: {
    backgroundColor: ({ cardIsEven }) =>
      cardIsEven ? primary.dark : secondary.main,
    color: ({ cardIsEven }) =>
      cardIsEven ? text.secondary : text.primary,
    position: 'relative',
    order: ({ order }) => order,
    [up('md')]: {
      minHeight: 1020,
      maxHeight: 1020,
    },
  },
  paddingContainer: {
    padding: spacing(13, 13, 9, 13),
    alignItems: ({ cardIsEven }) =>
      cardIsEven ? 'flex-start' : 'flex-end',
    textAlign: ({ cardIsEven }) =>
      cardIsEven ? 'left' : 'right',
    [down('md')]: {
      padding: spacing(13, 8, 9, 8),
    },
    [down('sm')]: {
      alignItems: 'flex-start!important',
      textAlign: 'left!important',
    },
    [down('xs')]: {
      padding: spacing(13, 4, 8, 4),
    },
  },
  nameContainer: {
    position: 'relative',
  },
  name: {
    fontSize: fonts.size.xxl,
    fontWeight: 700,
    position: 'relative',
    zIndex: 3,
    [down('md')]: {
      fontSize: fonts.size.xl,
    },
    [down('xs')]: {
      fontSize: fonts.size.l,
    },
  },
  description: {
    fontSize: fonts.size.xs,
    lineHeight: `${fonts.size.xs + 8}px`,
    marginTop: spacing(4),
    opacity: 0.9,
    whiteSpace: 'pre-line',
    display: 'flex',
    flexDirection: 'column',
    alignItems: ({ cardIsEven }) =>
      cardIsEven ? 'flex-start' : 'flex-end',
    [down('md')]: {
      fontSize: fonts.size.xs,
      lineHeight: `${fonts.size.xs + 6}px`,
    },
    [down('xs')]: {
      fontSize: fonts.size.xxs,
      lineHeight: `${fonts.size.xxs + 8}px`,
    },
  },
  chevron: {
    marginTop: spacing(4),
    animation: '$bounce 1.5s infinite',
    [down('sm')]: {
      display: 'none',
    },
  },
  '@keyframes bounce': {
    '0%': {
      transform: 'translateY(0)',
    },
    '50%': {
      transform: 'translateY(7px)',
    },
    '100%': {
      transform: 'translateY(0)',
    },
  },
  imageContainer: {
    width: '100%',
    bottom: 0,
    left: 0,
    [up('md')]: {
      position: 'absolute',
      maxHeight: 450,
      minHeight: 350,
      '& .gatsby-image-wrapper': {
        maxHeight: 450,
        minHeight: 350,
      },
      '& img': {
        maxHeight: 450,
        minHeight: 350,
      },
    },
    [up('lg')]: {
      maxHeight: 500,
      minHeight: 400,
      '& .gatsby-image-wrapper': {
        maxHeight: 500,
        minHeight: 400,
      },
      '& img': {
        maxHeight: 500,
        minHeight: 400,
      },
    },
    [up('xl')]: {
      maxHeight: 550,
      '& .gatsby-image-wrapper': {
        maxHeight: 530,
      },
      '& img': {
        maxHeight: 530,
      },
    },
  },
});
