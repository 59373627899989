import React from 'react';
import { string } from 'prop-types';
import { ButtonBase, Typography } from '@material-ui/core';
import { Link } from 'gatsby-plugin-intl';
import { makeStyles } from '@material-ui/styles';
import Arrow from '../../components/svg/Arrow';

const useStyles = makeStyles(
  ({
    palette: { primary, text },
    fonts,
    spacing,
    breakpoints: { down },
  }) => ({
    showMoreButton: {
      textAlign: 'left',
      display: 'flex',
      position: 'relative',
      zIndex: 3,
      transition: 'opacity .3s',
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&:hover': {
        opacity: 0.8,
        '& svg': {
          animation: '$hoverEffect 1.5s infinite',
        },
      },
    },
    showMoreLink: {
      textDecoration: 'none',
      color: text.primary,
    },
    arrow: {
      margin: spacing(1, 0, 0, 3),
      [down('xs')]: {
        margin: spacing(0, 0, 0, 1),
        width: 50,
        verticalAlign: -6,
      },
    },
    linkBase: {
      fontSize: fonts.size.l,
      fontWeight: 700,
      lineHeight: `${fonts.size.l + 8}px`,
      display: 'inline',
      [down('xs')]: {
        fontSize: fonts.size.xm,
        lineHeight: `${fonts.size.xm + 4}px`,
      },
    },
    underlinedText: {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 8,
        left: 0,
        height: 7,
        width: '100%',
        background: primary.dark,
        opacity: 0.2,
        zIndex: 1,
        [down('xs')]: {
          opacity: 0.1,
          bottom: 3,
        },
      },
    },
    otherText: {
      whiteSpace: 'pre',
    },
    '@keyframes hoverEffect': {
      '0%': {
        transform: 'translateX(0)',
      },
      '50%': {
        transform: 'translateX(10px)',
      },
      '100%': {
        transform: 'translateX(0)',
      },
    },
  })
);

const ShowAllStartupsButton = ({
  to,
  underlinedText,
  otherText,
}) => {
  const classes = useStyles();

  return (
    <ButtonBase className={classes.showMoreButton}>
      <Link to={to} className={classes.showMoreLink}>
        <Typography
          className={`${classes.linkBase} ${classes.underlinedText}`}
        >
          {underlinedText}
        </Typography>
        <Typography
          className={`${classes.linkBase} ${classes.otherText}`}
        >
          {otherText}
        </Typography>
        <Arrow tailed={true} className={classes.arrow} />
      </Link>
    </ButtonBase>
  );
};

export default ShowAllStartupsButton;

ShowAllStartupsButton.propTypes = {
  to: string,
  underlinedText: string,
  otherText: string,
};
