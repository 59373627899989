import React, { Fragment } from 'react';
import { shape } from 'prop-types';
import { Grid } from '@material-ui/core';
import QuoteCard from './QuoteCard';
import StartupCard from './StartupCard';
import useGetCurrentPath from '../../../../hooks/useGetCurrentPath';
import { makeStyles } from '@material-ui/styles';
import {
  calculateOrder,
  calculateStartupsContainerHeight,
} from '../../../../utils/startups-helper';
import useGetHomePageTranslation from '../../../../hooks/useGetHomePageTranslation';

const useStyles = makeStyles(({ breakpoints: { up } }) => ({
  startupContainer: {
    [up('md')]: {
      maxHeight: ({ totalHeight }) => totalHeight,
    },
  },
}));

const StartupSection = ({ data: { startups } }) => {
  const {
    startups: startupsTranslation,
  } = useGetHomePageTranslation();
  const { currentPath } = useGetCurrentPath();
  const currentPathIsHome = currentPath === '/';

  const totalHeight = calculateStartupsContainerHeight(
    startups.length
  );

  const classes = useStyles({
    totalHeight,
  });

  return (
    <Grid
      container
      direction="column"
      className={classes.startupContainer}
    >
      {startups.map(
        (
          {
            name,
            quote: { quote } = {},
            description: { description } = {},
            image: { fluid } = {},
          },
          index
        ) => {
          return (
            <Fragment key={quote ? quote : name}>
              {quote ? (
                <QuoteCard
                  quote={quote}
                  order={calculateOrder(
                    index,
                    index - 1,
                    startups
                  )}
                />
              ) : (
                <StartupCard
                  name={name}
                  description={description}
                  image={fluid}
                  order={calculateOrder(
                    index,
                    index - 1,
                    startups
                  )}
                />
              )}
            </Fragment>
          );
        }
      )}
      {currentPathIsHome && (
        <QuoteCard
          link={{
            underlinedLink:
              startupsTranslation.underlinedLink,
            normalLink: startupsTranslation.normalLink,
          }}
          order={startups.length + 1}
        />
      )}
    </Grid>
  );
};

StartupSection.propTypes = {
  data: shape({}),
};

export default StartupSection;
