import React from 'react';
import { number, shape, string } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import ShowAllStartupsButton from '../../ShowAllStartupsButton';
import styles from './QuoteCard.styles';

const useStyles = makeStyles(styles);

const QuoteCard = ({ quote, link, order }) => {
  const cardIsEven = order % 2 === 0 || order === 1;
  const classes = useStyles({
    cardIsEven,
    order,
  });

  return (
    <Grid
      container
      item
      sm={12}
      md={6}
      alignItems="center"
      className={classes.quoteContainer}
    >
      {quote && (
        <Typography className={classes.quote}>
          {quote}
        </Typography>
      )}
      {link && (
        <ShowAllStartupsButton
          to="/startups"
          otherText={link.normalLink}
          underlinedText={link.underlinedLink}
        />
      )}
    </Grid>
  );
};

QuoteCard.propTypes = {
  quote: string,
  link: shape({
    underlinedLink: string,
    normalLink: string,
  }),
  order: number,
};

export default QuoteCard;
