export default ({
  fonts,
  palette: { primary, secondary, text },
  spacing,
  breakpoints: { down, up },
}) => ({
  quoteContainer: {
    padding: spacing(13),
    backgroundColor: ({ cardIsEven }) =>
      cardIsEven ? primary.main : secondary.dark,
    color: text.primary,
    position: 'relative',
    overflow: 'hidden',
    order: ({ order }) => order,
    [up('md')]: {
      minHeight: 380,
      maxHeight: 380,
    },
    [down('md')]: {
      padding: spacing(8),
    },
    [down('xs')]: {
      padding: spacing(8, 4),
    },
  },
  quote: {
    fontSize: fonts.size.xl,
    lineHeight: `${fonts.size.xl + 4}px`,
    fontWeight: 700,
    whiteSpace: 'pre-line',
    position: 'relative',
    zIndex: 3,
    [down('md')]: {
      fontSize: fonts.size.l,
      lineHeight: `${fonts.size.l + 4}px`,
    },
    [down('xs')]: {
      fontSize: fonts.size.xxxm,
      lineHeight: `${fonts.size.xxxm + 6}px`,
    },
    [down(325)]: {
      fontSize: fonts.size.xxxm - 2,
      lineHeight: `${fonts.size.xxxm + 4}px`,
    },
  },
});
