import React from 'react';
import { string, shape, number } from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import Image from 'gatsby-image';
import ChevronDown from '../../../svg/ChevronDown';
import styles from './StartupCard.styles';

const useStyles = makeStyles(styles);

const StartupCard = ({
  name,
  description,
  image,
  order,
}) => {
  const cardIsEven = order % 2 === 0;
  const classes = useStyles({
    cardIsEven,
    order,
  });

  return (
    <Grid
      container
      item
      sm={12}
      md={6}
      direction="column"
      className={classes.startupCardContainer}
    >
      <Grid
        container
        item
        direction="column"
        className={classes.paddingContainer}
      >
        <Box className={classes.nameContainer}>
          <Typography variant="h3" className={classes.name}>
            {name}
          </Typography>
        </Box>
        <Typography className={classes.description}>
          {description}
          <ChevronDown className={classes.chevron} />
        </Typography>
      </Grid>
      <Box className={classes.imageContainer}>
        <Image fluid={image} />
      </Box>
    </Grid>
  );
};

StartupCard.propTypes = {
  name: string,
  description: string,
  image: shape({}),
  order: number,
};

export default StartupCard;
