import patterns from '../../../../constants/patterns';

export default ({
  palette: { primary, secondary, text },
  breakpoints: { down },
  spacing,
  fonts,
}) => ({
  headerSection: {
    backgroundColor: ({ isDarkPath }) =>
      isDarkPath ? primary.dark : secondary.dark,
    color: ({ isDarkPath }) =>
      isDarkPath ? text.secondary : text.primary,
    height: '100vh',
    overflow: 'hidden',
    [down('xs')]: {
      height: ({ height }) => height,
      minHeight: ({ height }) => height,
    },
    [down(325)]: {
      height: 'auto!important',
    },
  },
  container: {
    position: 'relative',
    zIndex: 3,
  },
  textContainer: {
    maxWidth: 550,
    position: 'relative',
    [down('xs')]: {
      maxWidth: '100%',
    },
    [down(325)]: {
      padding: spacing(10, 0, 30, 0),
    },
  },
  title: {
    whiteSpace: 'pre-line',
    lineHeight: `${fonts.size.xxl + 4}px`,
    [down('xs')]: {
      whiteSpace: 'initial',
      fontSize: fonts.size.l - 1,
      lineHeight: `${fonts.size.l + 3}px`,
    },
  },
  description: {
    whiteSpace: 'pre-line',
    marginTop: spacing(5),
    maxWidth: 420,
    lineHeight: `${fonts.size.xs + 8}px`,
    opacity: 0.9,
    [down('xs')]: {
      fontSize: fonts.size.xxs,
      lineHeight: `${fonts.size.xxs + 8}px`,
      paddingRight: spacing(2),
    },
  },
  pattern: {
    width: '50%',
    height: '100%',
    position: 'absolute',
    background: ({ isDarkPath }) =>
      `url(${
        patterns[isDarkPath ? 'archLight' : 'arrow']
      })`,
    right: 0,
    bottom: 0,
    opacity: 0.2,
    [down('xs')]: {
      width: ({ isDarkPath }) =>
        isDarkPath ? '70%' : '100%',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      right: 0,
      height: '100%',
      width: '100%',
      background: ({ isDarkPath }) =>
        isDarkPath
          ? `radial-gradient(71.74% 61.58% at 88.75% 18.11%, rgba(29, 28, 34, 0.04) 0%, ${primary.dark} 100%)`
          : `radial-gradient(29.93% 38.14% at 21.66% 22.85%, rgba(209, 214, 206, 0.04) 0%, ${secondary.dark} 100%)`,
      transform: ({ isDarkPath }) =>
        isDarkPath
          ? 'matrix(1, 0, 0, -1, 0, 0)'
          : 'rotate(-180deg)',
    },
  },
});
