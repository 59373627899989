import React from 'react';
import { string } from 'prop-types';

const ChevronDown = ({ className }) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity="0.5"
        d="M15.0711 7.07107L8 14.1421L0.928932 7.07107"
        stroke="currentColor"
      />
    </svg>
  );
};

ChevronDown.propTypes = {
  className: string,
};

export default ChevronDown;
