import { graphql } from 'gatsby';
import React from 'react';
import Seo from '../components/config/Seo';
import StartupsSection from '../components/shared/sections/startups-section';
import HeaderSection from '../components/shared/sections/header-section';
import useGetStartupsPageTranslation from '../hooks/useGetStartupsPageTranslation';

const Startups = ({ data }) => {
  const {
    pageTitle,
    headerSection,
  } = useGetStartupsPageTranslation();
  return (
    <>
      <Seo title={pageTitle} />
      <HeaderSection
        title={headerSection.title}
        description={headerSection.description}
      />
      <StartupsSection
        data={data.allContentfulStartupList.edges[0].node}
      />
    </>
  );
};

export default Startups;

export const query = graphql`
  query StartupsPage {
    allContentfulStartupList {
      edges {
        node {
          startups {
            ... on ContentfulStartupEntry {
              name
              description {
                description
              }
              image {
                fluid(quality: 85) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
            ... on ContentfulStartupQuoteEntry {
              quote {
                quote
              }
            }
          }
        }
      }
    }
  }
`;
