import { useIntl } from 'gatsby-plugin-intl';
import { useMemo } from 'react';

const useGetStartupsPageTranslation = () => {
  const { formatMessage: t, locale } = useIntl();
  return useMemo(() => {
    if (locale && t) {
      return {
        pageTitle: t({ id: 'pages.startups' }),
        headerSection: {
          title: t({ id: 'startups.sections.0.title' }),
          description: t({
            id: 'startups.sections.0.description',
          }),
        },
      };
    } else {
      return {
        pageTitle: '',
        headerSection: {
          title: '',
          description: '',
        },
      };
    }
  }, [locale, t]);
};

export default useGetStartupsPageTranslation;
