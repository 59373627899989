import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Container from '../../../shared/Container';
import { string } from 'prop-types';
import useGetCurrentPath from '../../../../hooks/useGetCurrentPath';
import styles from './index.styles';
import useGetWindowHeight from '../../../../hooks/useGetWindowHeight';

const useStyles = makeStyles(styles);

const HeaderSection = ({ title, description }) => {
  const { currentPath } = useGetCurrentPath();
  const isDarkPath = ['/startups', '/startups/'].includes(
    currentPath
  );
  const height = useGetWindowHeight();
  const classes = useStyles({ isDarkPath, height });

  return (
    <Grid
      container
      className={classes.headerSection}
      justify="center"
      direction="column"
    >
      <Container className={classes.container}>
        <Grid container className={classes.textContainer}>
          <Typography
            variant="h2"
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography className={classes.description}>
            {description}
          </Typography>
        </Grid>
      </Container>
      <Box className={classes.pattern} />
    </Grid>
  );
};

HeaderSection.propTypes = {
  title: string,
  description: string,
};

export default HeaderSection;
